<template>
  <div>
    <global-header></global-header>
    <div class="sctp-container sctp-mar-tb15 sctp-bg-white">
      <div style="padding: 20px;background-color:#e3e3e3;font-size: 20px;">订单支付</div>
      <div style="width: 80%;margin: 0 auto;">
        <div style="padding: 40px 0;">
          <el-steps :active="buyStep" align-center>
            <el-step title="确认订单信息"></el-step>
            <el-step title="支付"></el-step>
            <el-step title="交易成功"></el-step>
          </el-steps>
        </div>
        <div style="">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <global-footer></global-footer>
  </div>
</template>

<script>
export default {
  name: 'orderBy',
  components: {
    GlobalHeader: () => import('@CMP/common/GlobalHeader'),
    GlobalFooter: () => import('@CMP/common/GlobalFooter')
  },
  data() {
    return {
      addressArray: [],
      address: null
    }
  },
  computed: {
    buyStep() {
      let step = 1;
      const path = this.$route.path;
      if (path.startsWith('/order/payResult')) {
        step = 3
      } else if (path.startsWith('/order/pay')) {
        step = 2
      }
      return step
    }
  },
  methods: {
    /**
     * 下单
     */
    submitOrder() {

    }
  },
  beforeMount() {
  }
}
</script>

<style scoped lang="scss">
.el-input-number--small {
  width: 120px;
}

.sctp-card {
  padding: 15px;
}

.sctp-card .sctp-card-header .title {
  margin: 15px 0;
  border-left: 3px solid #F90;
  padding-left: 15px;
  color: #F90;
}

.goods-list {
  border: #dedede solid 1px;
  padding: 0 15px;
}

.goods-item + .goods-item {
  border-top: #E7E7E7 solid 1px;
}
</style>
